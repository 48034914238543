<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <!-- 필수항목 목록 -->
        <c-table
          ref="table3"
          title="LBL0002557"
          :columns="grid2.columns"
          :gridHeight="grid2.height"
          :data="data.rsltItemModelList"
          :filtering="false"
          :isExcelDown="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="editable"
          rowKey="hazardousMachineryInspectionResultItemId"
        >
          <template v-slot:suffixTitle>
            <font style="font-size:0.8em;font-weight:300;">
              &nbsp;&nbsp;
              {{$label('LBL0002577')}} &nbsp;&nbsp;
              {{$label('LBL0002578')}} &nbsp;&nbsp;
              {{$label('LBL0002579')}} &nbsp;&nbsp;
              {{$label('LBL0002580')}} &nbsp;&nbsp;
            </font>
          </template>
          <template slot="table-button">
            <q-btn-group outline >
              <!-- 저장 -->
              <c-btn
                v-if="editable && data.rsltItemModelList.length > 0"
                :url="saveUrl2"
                :isSubmit="isSave2"
                :param="data.rsltItemModelList"
                mappingType="POST"
                label="LBLSAVE"
                icon="save"
                @beforeAction="save2"
                @btnCallback="saveCallback" 
              />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props }">
          <template v-if="props.row.testMothod">
            <q-chip
              v-for="(name, index) in $_.split(props.row.testMothodName, ',')"
              :key="index"
              outline square 
              color="orange"
              text-color="white"
              class="q-ma-none"
              >
              {{name + ' '}}
            </q-chip>
          </template>
        </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from "@/js/selectConfig";
import transactionConfig from "@/js/transactionConfig";
export default {
  name: "hazard-equipment-history-result",

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        hhmHazardousMachineryId: '',
        inspectionNo: '',
        machineryClassification: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      data: {
        hhmHazardousMachineryId: null,
        inspectionResultCd: null,
        inspTgtModelList: [],
        rsltItemModelList: [],
        rsltMngModelList: [],
      },
      disabled: false,
        
      grid: {
        columns: [
          {
            name: "itemName",
            field: "itemName",
            // 검사항목
            label: "LBL0002558",
            style: 'width:350px',
            align: "center",
            sortable: false,
          },
          {
            required: true,
            setHeader: true,
            name: "inspectionFlag",
            field: "inspectionFlag",
            // 점검결과
            label: "LBL0002559",
            style: 'width:100px',
            align: "center",
            type: 'select',
            sortable: false,
            comboItems: [
              { code: 'Y', codeName: '합격' },
              { code: 'N', codeName: '불합격' },
            ]
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            // 조항
            label: 'LBL0002560',
            align: 'center',
            style: 'width:60px',
            sortable: false,
          },
        ],
        data: [],
        height: '500px'
      },
      grid2: {
        columns: [
          {
            name: "itemName",
            field: "itemName",
            // 검사항목
            label: "LBL0002558",
            style: 'width:150px',
            align: "center",
            sortable: false,
          },
          {
            name: "itemCriterion",
            field: "itemCriterion",
            // 판정기준
            label: "LBL0002561",
            style: 'width:400px',
            align: "left",
            sortable: false,
          },
          {
            required: true,
            setHeader: true,
            name: "inspectionFlag",
            field: "inspectionFlag",
            // 점검(판정)결과
            label: "LBL0002562",
            style: 'width:150px',
            align: "center",
            type: 'select',
            sortable: false,
            comboItems: [
              { code: 'Y', codeName: '합격' },
              { code: 'N', codeName: '불합격' },
              { code: 'E', codeName: '해당없음' },
            ]
          },
          {
            name: 'testMothod',
            field: 'testMothod',
            // 검사방법
            label: 'LBL0002563',
            align: 'center',
            style: 'width:150px',
            type: 'custom',
            sortable: false,
          },
          {
            name: 'useEquip',
            field: 'useEquip',
            // 활용장비
            label: 'LBL0002564',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'testResult',
            field: 'testResult',
            // 검사결과
            label: 'LBL0002565',
            align: 'left',
            style: 'width:250px',
            type: 'text',
            sortable: false,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            // 조항
            label: 'LBL0002560',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
        ],
        data: [],
        height: '850px'
      },
      grid3: {
        columns: [
          {
            required: true,
            name: "itemName",
            field: "itemName",
            // 검사항목
            label: "LBL0002558",
            style: 'width:150px',
            align: "left",
            type: "text",
            sortable: false,
          },
          {
            required: true,
            name: "inspectionResult",
            field: "inspectionResult",
            // 검사결과(안전검사고시 미달내용 기재)
            label: "LBL0002567",
            align: "left",
            type: "text",
            sortable: false,
          },
          {
            name: "article",
            field: "article",
            // 근거조항
            label: "LBL0002568",
            style: 'width:100px',
            align: "left",
            type: "text",
            sortable: false,
          },
          {
            name: "sortOrder",
            field: "sortOrder",
            // 순서
            label: "LBL0002569",
            style: 'width:70px',
            align: "right",
            type: "number",
            sortable: false,
          },
        ],
        data: [],
        height: '600px'
      },
      deleteUrl: "",
      updateUrl: "",
      updateMode: false,
      getUrl: "",
      saveUrl2: '',
      saveUrl3: '',
      saveType: 'POST',
      isSave: false,
      isSave2: false,
      isSave3: false,
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
      this.init();
  },
  beforeDestroy() {},
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.getUrl = selectConfig.sop.hhm.hazard.hazardHistory.result.get.url;
      this.saveUrl2 = transactionConfig.sop.hhm.hazard.hazardHistory.result.save2.url;

      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.inspectionNo) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.inspectionNo, this.popupParam.machineryClassification);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = this.$_.clone(_result.data);
        },);
      } 
    },
    save() {
      if (this.$comm.validTable(this.grid.columns, this.data.inspTgtModelList)) {
        window.getApp.$emit("CONFIRM", {
          title: "LBLCONFIRM",
          message: "MSGSAVE",
          // TODO : 필요시 추가하세요.
          type: "info", // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.data.inspTgtModelList, _item => {
              _item.inspectionNo = this.popupParam.inspectionNo;
              _item.regUserId = this.$store.getters.user.userId;
              _item.chgUserId = this.$store.getters.user.userId;
            })
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {},
        });
      }
    },
    save2() {
      if (this.$comm.validTable(this.grid2.columns, this.data.rsltItemModelList)) {
        window.getApp.$emit("CONFIRM", {
          title: "LBLCONFIRM",
          message: "MSGSAVE",
          // TODO : 필요시 추가하세요.
          type: "info", // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.data.rsltItemModelList, _item => {
              _item.inspectionNo = this.popupParam.inspectionNo;
              _item.regUserId = this.$store.getters.user.userId;
              _item.chgUserId = this.$store.getters.user.userId;
            })
            this.isSave2 = !this.isSave2;
          },
          // 취소 callback 함수
          cancelCallback: () => {},
        });
      }
    },
    saveCallback() {
      window.getApp.$emit("APP_REQUEST_SUCCESS");
      this.getDetail();
    },
  },
};
</script>
